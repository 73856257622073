<template>
  <div class="eventManagement-form-box">
    <head-layout
      head-title="风险信息"
      :head-btn-options="headBtnOptions"
      @head-save="headSave(false)"
      @head-cancel="headCancel"
    ></head-layout>
    <div class="formContentBox">
      <div class="formMain">
        <div style="padding: 0 12px">
          <el-form
            ref="dataForm"
            :model="ruleForm"
            label-width="150px"
            :disabled="formType == 'view'"
            :rules="rules"
          >
            <el-row>
              <el-col :span="8">
                <el-form-item label="风险源" prop="sourceId">
                  <avue-select
                    :props="{
                      label: 'sourceName',
                      value: 'id',
                    }"
                    v-model="ruleForm.sourceId"
                    placeholder="请选择事故类别"
                    type="tree"
                    :dic="strisksourceList"
                  ></avue-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="事故类型" prop="event">
                  <avue-select
                    :props="{
                      label: 'dictValue',
                      value: 'dictKey',
                    }"
                    v-model="ruleForm.event"
                    placeholder="请选择事故类别"
                    type="tree"
                    :dic="RISK_EVENT_TYPE"
                  ></avue-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="管控层级" prop="controlLevel">
                  <avue-select
                    :props="{
                      label: 'dictValue',
                      value: 'dictKey',
                    }"
                    v-model="ruleForm.controlLevel"
                    placeholder="请选择事故类别"
                    type="tree"
                    :dic="RISK_CONTROL_LEVEL"
                  ></avue-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="风险源描述" prop="riskName">
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 2 }"
                    show-word-limit
                    v-model="ruleForm.riskName"
                    maxlength="225"
                    placeholder="请输入事故发生的简要经过和发生的原因"
                  ></el-input>
                </el-form-item>
              </el-col>


              <el-col :span="8">
                <el-form-item label="发生可能性:">
                  <el-select
                    v-model="ruleForm.lecdL"
                    placeholder="请选择发生可能性"
                    @change="handleChangeL"
                  >
                    <el-option
                      v-for="item in L"
                      :label="item.definition"
                      :value="item.id"
                      :key="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="3">
                <el-form-item label=""> </el-form-item>
              </el-col> -->
              <el-col :span="8">
                <el-form-item label-width="170px" label="人员暴露风险环境繁度:">
                  <el-select
                    v-model="ruleForm.lecdE"
                    placeholder="请选择人员暴露风险环境繁度"
                    @change="handleChangeE"
                  >
                    <el-option
                      v-for="item in E"
                      :label="item.definition"
                      :value="item.id"
                      :key="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="可能后果:">
                  <el-select
                    v-model="ruleForm.lecdC"
                    @change="handleChangeC"
                    placeholder="请选择可能后果"
                  >
                    <el-option
                      v-for="item in C"
                      :label="item.definition"
                      :value="item.id"
                      :key="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="L:">
                  <el-input-number
                    v-model="ruleForm.lecdLScore"
                    @input="handleChange"
                    :min="0"
                  ></el-input-number>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="E:">
                  <el-input-number
                    v-model="ruleForm.lecdEScore"
                    @input="handleChange"
                    :min="0"
                  ></el-input-number>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="C:">
                  <el-input-number
                    v-model="ruleForm.lecdCScore"
                    @input="handleChange"
                    :min="0"
                  ></el-input-number>
                </el-form-item>
              </el-col>
              <el-col :span="5" class="class-D">
                <el-form-item label="D:">
                  <el-input
                    style="width: calc(100% - 30px)"
                    v-model="ruleForm.lecdDScore"
                    :min="0"
                    default="0"
                    :disabled="true"
                  ></el-input>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="LEC风险评价法：L（Likelihood，事故发生的可能性）、E（Exposure，人员暴露于危险环境中的频繁程度）、C（Consequence，一旦发生事故可能造成的后果）。给三种因素的不同等级分别确定不同的分值，再以三个分值的乘积D（danger，危险性）来评价风险的大小。注：自行选择风险等级后D值会清空，采用用户的选择"
                    placement="right-start"
                  >
                    <i
                      class="el-icon-question"
                      style="margin-left: 10px; font-size: 20px"
                    ></i>
                  </el-tooltip>
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item label="风险等级:" prop="riskLevel">
                  <!-- <el-input
                    v-model="ruleForm.riskLevel"
                    placeholder="系统自动计算"
                    disabled
                  ></el-input> -->
                  <el-select
                    v-model="ruleForm.riskLevel"
                    placeholder="系统自动计算（也可自行选择）"
                    clearable
                    @change="riskLevelChange"
                  >
                    <el-option
                      v-for="item in D"
                      :label="item.levelName"
                      :value="item.levelName"
                      :key="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
    </div>

    <head-layout
      head-title="管控措施"
    ></head-layout>
    <div
      class="formContentBox"
    >
      <div class="formMain">
        <div style="padding: 0 12px">
          <el-form
            ref="dataForm1"
            :model="ruleForm"
            label-width="150px"
            :disabled="formType == 'view'"
            :rules="rules"
          >
            <el-row>
              <el-col :span="24">
                <el-form-item label="工程技术" prop="treatEngineer">
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 2 }"
                    show-word-limit
                    v-model="ruleForm.treatEngineer"
                    maxlength="225"
                    placeholder="请输入工程技术内容"
                  ></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="24">
                <el-form-item label="管理措施" prop="treatManagment">
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 2 }"
                    show-word-limit
                    v-model="ruleForm.treatManagment"
                    maxlength="225"
                    placeholder="请输入管理措施内容"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="培训教育" prop="treatTraining">
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 2 }"
                    show-word-limit
                    v-model="ruleForm.treatTraining"
                    maxlength="225"
                    placeholder="请输入培训教育内容"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="个体防护" prop="treatProtection">
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 2 }"
                    show-word-limit
                    v-model="ruleForm.treatProtection"
                    maxlength="225"
                    placeholder="请输入个体防护内容"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="应急处置" prop="treatEmergency">
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 2 }"
                    show-word-limit
                    v-model="ruleForm.treatEmergency"
                    maxlength="225"
                    placeholder="请输入个体防护内容"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>

          </el-form>
        </div>
      </div>
    </div>


  </div>
</template>
<script>
import * as API from "@/api/safetyTtandard/riskIdentification";

import {getToken} from "@/util/auth";
import {dateFormat} from "@/util/date";
import {mapGetters} from "vuex";
import Template from "@/views/message/template/list.vue";
import {
  getbsRiskLecdCMap,
  getbsrisklecddlevelMap,
  getbsrisklecdeMap,
  getbsRiskLecdLMap
} from "@/api/setting/riskAssessment";
import {sthdlistDetail, sthdlistSubmit} from "@/api/safetyTtandard/riskIdentification";

export default {
  components: {
    Template,
  },
  data() {
    return {
      strisksourceList: [],
      L: [],
      E: [],
      C: [],
      D: [],
      type: "",
      showFileDialog: false,
      deptShow: false,
      RISK_EVENT_TYPE: [],
      RISK_CONTROL_LEVEL: [],
      selectNode: {},
      formType: "",
      fileUrl: "",
      mapBox: false,
      ruleForm: {},
      tableData: [],
      tableLoading: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      rules: {
        sourceId: [
          {
            required: true,
            message: "请选择风险源",
            trigger: "change",
          },
        ],
        event: [
          {
            required: true,
            message: "请选择事故类型",
            trigger: "change",
          },
        ],
        controlLevel: [
          {
            required: true,
            message: "请选择管控层级",
            trigger: "change",
          },
        ],
        riskLevel: [
          {
            required: true,
            message: "请选择风险等级",
            trigger: "change",
          },
        ],
        riskName: [
          {
            required: true,
            message: "请输入风险源描述",
            trigger: "blur",
          },
        ],
        treatEngineer: [{required: true, message: "请输入工程技术", trigger: "blur",}],
        treatManagment: [{required: true, message: "请输入管理措施", trigger: "blur",}],
        treatTraining: [{required: true, message: "请输入培训教育", trigger: "blur",}],
        treatProtection: [{required: true, message: "请输入个体防护", trigger: "blur",}],
        treatEmergency: [{required: true, message: "请输入应急处置", trigger: "blur",}],
      },
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    headers: function () {
      return {"Sinoma-Auth": getToken()};
    },
    headBtnOptions() {
      let result = [];
      if (["add", "edit"].includes(this.formType)) {
        result.push({
          label: "保存",
          emit: "head-save",
          type: "button",
          btnOptType: "save",
        });

      }
      result.push({
        label: "取消",
        emit: "head-cancel",
        type: "button",
        btnOptType: "cancel",
      });
      return result;
    },
  },
  async mounted() {
    let type = this.$route.query.type;
    this.formType = type;
    if (type == "edit") {
      this.$loading();
      this.ruleForm.id = this.$route.query.id;
      this.getPrjbasicinfoDetail();
    } else if (type == "view") {
      this.$loading();
      this.ruleForm.id = this.$route.query.id;
      this.getPrjbasicinfoDetail();
    } else {
      this.$set(this.ruleForm, "reportDeptId", this.userInfo.dept_id);
      this.$set(this.ruleForm, "reportDeptName", this.userInfo.deptName);
      this.$set(this.ruleForm, "userId", this.userInfo.user_id);
      this.$set(this.ruleForm, "userName", this.userInfo.real_name);
      this.$set(this.ruleForm, "sourceId", this.$route.query.sourceId);
      this.$set(this.ruleForm, "sectionId", this.$route.query.sectionId);
    }
    await this.getCode("RISK_EVENT_TYPE");
    await this.getCode("RISK_CONTROL_LEVEL");
    this.getbsRiskLecdLMap()
  },
  methods: {
    async getCode(code) {
      await API.HD_SOURCE_TYPE(code).then(res => {
        this[code] = res.data.data
      })
    },
    getbsRiskLecdLMap() {
      API.list({}).then((res) => {
        this.strisksourceList = res.data.data;
      });
      getbsRiskLecdLMap({}).then((res) => {
        this.L = res.data.data;
      });
      getbsrisklecdeMap({}).then((res) => {
        this.E = res.data.data;
      });
      getbsRiskLecdCMap({}).then((res) => {
        this.C = res.data.data;
      });
      getbsrisklecddlevelMap({}).then((res) => {
        this.D = res.data.data;
      });
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    // 保存
    async headSave(boolean) {
      let valid = await this.$refs.dataForm.validate().catch((err) => {
        return false;
      });
      if (!valid) return;
      if (this.$refs.dataForm1) {
        let valid1 = await this.$refs.dataForm1?.validate().catch((err) => {
          return false;
        });
        if (!valid1) return;
      }
      this.$loading();
      API.sthdlistSubmit({
        ...this.ruleForm,
        organizationId: this.userInfo.dept_id,
        organizationName: this.userInfo.deptName,
      })
        .then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: !boolean ? "保存成功" : "提交成功",
              type: "success",
            });
            this.$loading().close();
            this.$router.$avueRouter.closeTag();
            this.$router.back();
          }
        })
        .catch((err) => {
          this.$loading().close();
        });
    },
    // 根据ID查表单详情
    getPrjbasicinfoDetail() {
      API.sthdlistDetail({
        id: this.ruleForm.id,
      })
        .then((res) => {
          if (res.data.code == 200) {
            this.$loading().close();
            this.ruleForm = res.data.data;
          }
        })
        .catch((err) => {
          this.$loading().close();
        });
    },
    handleChangeL(e) {
      const result = this.L.find((item) => item.id == e);
      this.ruleForm.lecdL = result.definition;
      this.ruleForm.lecdLScore = result.score;
      this.computedlecdDScore();
    },
    handleChangeE(e) {
      const result = this.E.find((item) => item.id == e);
      this.ruleForm.lecdE = result.definition;
      this.ruleForm.lecdEScore = result.score;
      this.computedlecdDScore();
    },
    handleChangeC(e) {
      const result = this.C.find((item) => item.id == e);
      this.ruleForm.lecdC = result.definition;
      this.ruleForm.lecdCScore = result.score;
      this.computedlecdDScore();
    },
    handleChange() {
      this.needCalculate = 1;
      if (this.ruleForm.needCalculate == 1) {
        this.computedlecdDScore();
      }
      // this.computedlecdDScore();
    },
    // 计算D值
    computedlecdDScore() {
      this.ruleForm.lecdDScore =
        this.ruleForm.lecdLScore *
        this.ruleForm.lecdEScore *
        this.ruleForm.lecdCScore || 0;
      this.computedriskLevel();
    },
    // 计算风险等级
    computedriskLevel() {
      window.D = this.ruleForm.lecdDScore;
      const level = this.D.find((item) => {
        if (window.eval(item.formula)) {
          return item;
        }
      });
      if (level) {
        this.ruleForm.riskLevel = level.levelName;
        this.ruleForm.lecdColor = level.color;
        this.ruleForm.lecdD = level.levelCode;
      }
      return level || {};
    },
    riskLevelChange(val) {
      this.D.map((item) => {
        if (val == item.levelName) {
          this.ruleForm.lecdColor = item.color;
          this.ruleForm.lecdD = item.levelCode;
          this.needCalculate = 0;
        }
      });
    },
  },
};
</script>
<style lang="scss">
.eventManagement-form-box {
  background-color: #ffffff;
  height: auto !important;
  .formContentBox {
    padding: 12px;
  }

  .formMain {
    width: 100%;
    overflow: auto;
  }

  .step-box {
    background-color: #ffffff;
    width: 125px;
  }

  .formTopic {
    width: 100%;
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid rgb(187, 187, 187);
    font-weight: 600 !important;
    font-size: 18px;
  }

  .shuttleBackBox {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;

    .el-tabs {
      width: 100%;
    }

    .table-box {
      width: calc(100% - 280px);
    }
  }

  .lossIndirect {
    display: inline-block;
    color: #909399;
    vertical-align: middle;
    position: relative;
    border-radius: 4px;
    padding: 0 10px;
    white-space: nowrap;
  }

  .shutleBack {
    width: 33%;
    background: #ffffff;
    border: 1px solid #ebeef5;
    border-radius: 4px;
  }

  .leftRightBtn {
    margin: 211px 12px;
  }

  .shutleTitle {
    width: 100%;
    background-color: #f5f7fa;
    height: 40px;
    line-height: 40px;
    text-indent: 12px;
    border-bottom: 1px solid #ebeef5;
    font-size: 16px;
  }

  .shutContent {
    padding: 6px;
    height: 400px;
    overflow: auto;
  }

  .deptBox {
    line-height: 40px;
    color: #333333;
    border-bottom: 1px solid #ebeef5;
    font-size: 13px;
    display: flex;
  }

  .unitBox {
    width: 100%;
    display: flex;
    background-color: #f5f7fa;
    border-bottom: 1px solid #ebeef5;
  }

  .unitTitle {
    height: 40px;
    line-height: 40px;
    padding: 0 12px;
    font-size: 16px;
    cursor: pointer;
    border-right: 1px solid #ebeef5;
  }

  .content {
    display: flex;
    align-items: center;

    .img-content {
      width: 188px;
      height: 100px;
      padding: 3px;
      position: relative;
      border: 1px solid #ccc;
      border-radius: 3px;
      margin-right: 12px;

      .delete-icon {
        position: absolute;
        top: -6px;
        right: -6px;
        font-size: 16px;
        color: red;
      }

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
