<template>
  <div class="main-content">
    <div style="position: relative" class="content">

      <el-container>
        <CommonTreeNew
          ref="commonTree"
          treeTitle="组织架构"
          :defaultProps="Props"
          :showCheckbox="false"
          @getNodeClick="handleNode"
          :urlParmas="{
            tenantId: '',
            deptCategory: '5,2',
            parentId: userInfo.dept_id,
          }"
          node-key="id"
          :isShowdig="false"
          :isZoom="true"
          :show="show"
          @showChange="showChange"
        ></CommonTreeNew>
        <CommonTree
          treeTitle="风险源"
          class="riskClass"
          :defaultProps="defaultProps"
          :isShowdig="pageType != 'workflow'&&riskSource"
          :searchTitle="searchTitle"
          :showCheckbox="false"
          :treeData="treeData"
          :treeExpand="false"
          :risk="true"
          node-key="id"
          :defaultCheckedKeys="defaultCheckedKeys"
          :defaultExpandedKeys="defaultExpandedKeys"
          @getNodeClick="handleNodeClick"
          @getTreeAdd="getTreeAdd"
          @getTreeDelete="getTreeDelete"
          @getTreeEdit="getTreeEdit"
        />
        <el-main style="position: relative">
          <div class="tagBox">
            <el-tag size="medium" type="info" v-if="quarterItme.status == '1'"
            >草稿
            </el-tag
            >
            <el-tag
              size="medium"
              type="success"
              v-if="quarterItme.status == '3'"
            >已发布
            </el-tag
            >
            <el-tag
              size="medium"
              type="warning"
              v-if="quarterItme.status == '2'"
            >已提交
            </el-tag
            >
            <el-tag size="medium" type="danger" v-if="quarterItme.status == '4'"
            >被驳回
            </el-tag
            >
          </div>
          <head-layout
            :head-btn-options="headBtnOptions"
            head-title="风险库"
            @head-add="opDialog()"
            @head-delete="deletesList"
            @head-export="handleExport"
            @head-release="handleRelease"
            @head-import="handleImport"
            @head-introduce="headIntroduce"
          >
          </head-layout>
          <grid-head-layout
            ref="searchForm"
            :searchSpan="'4'"
            :search-columns="searchColumns"
            @grid-head-search="searchChange"
            @grid-head-empty="searchReset"
          />
          <grid-layout
            ref="gridLayout"
            :data-total="tableTotal"
            :page="page"
            :tableData="tableData"
            :tableLoading="tableLoading"
            :tableOptions="tableOptions"
            @page-size-change="handleSizeChange"
            @page-current-change="handleCurrentChange"
            @page-refresh-change="onLoad"
            @gird-handle-select-click="selectionChange"
            :search-columns="searchColumns"
          >
            <template slot="riskLevel" slot-scope="{ row }">
              <div
                v-if="row.lecdD"
                style="
                  display: flex;
                  justify-content: center;
                  align-item: center;
                  padding: 4px;
                "
              >
                <div
                  :style="{
                    border: `1px solid ${row.lecdColor}`,
                    borderRadius: '4px',
                    color: row.lecdColor,
                    backgroundColor: computedDlevel(row.lecdD).bg,
                    textAlign: 'center',
                    display: 'inline-block',
                    padding: '0 20px',
                  }"
                >
                  {{ row.lecdD }} 级
                </div>
              </div>
            </template>
            <template slot="lecdDScore" slot-scope="{ row }">
              <div>{{ row.lecdDScore == -1 ? "" : row.lecdDScore }}</div>
            </template>
            <template slot="riskCode" slot-scope="{ row }">
              <el-link type="primary" @click="linkDetail(row)"
              >{{ row.riskCode }}
              </el-link>
            </template>
            <template
              #customBtn="{ row, index }"
              v-if="quarterItme.status == '1'"
            >
              <el-button
                type="text"
                size="small"
                v-if="row.status == 1 && row.createUser == userInfo.user_id"
                @click="rowEdit(row, index)"
              >
                编辑
              </el-button>
              <!--            <el-button-->
              <!--              type="text"-->
              <!--              size="small"-->
              <!--              v-if="row.status == 1"-->
              <!--              @click="rowRelease(row, index)"-->
              <!--            >-->
              <!--              发布-->
              <!--            </el-button>-->
              <el-button
                type="text"
                size="small"
                @click="linkDetail(row, index)"
              >
                查看
              </el-button>
              <el-button
                type="text"
                size="small"
                v-if="row.status == 1 && row.createUser == userInfo.user_id"
                @click="rowDel(row, index)"
              >
                删除
              </el-button>
            </template>
          </grid-layout>
        </el-main>
      </el-container>
    </div>
    <!--    <el-dialog title="风险库更新" :visible.sync="quarterShow" width="500px">-->
    <!--      <el-form-->
    <!--        :model="quarterForm"-->
    <!--        :rules="quarterFormRules"-->
    <!--        ref="quarterForm"-->
    <!--        label-width="75px"-->
    <!--      >-->
    <!--        <el-form-item label="名称：" prop="riskVersionName">-->
    <!--          <el-input-->
    <!--            v-model="quarterForm.riskVersionName"-->
    <!--            maxlength="10"-->
    <!--            placeholder="请输入名称"-->
    <!--          ></el-input>-->
    <!--        </el-form-item>-->
    <!--      </el-form>-->

    <!--      <span slot="footer" class="dialog-footer">-->
    <!--        <el-button @click="quarterShow = false">取 消</el-button>-->
    <!--        <el-button type="primary" @click="submitQuarter">确 定</el-button>-->
    <!--      </span>-->
    <!--    </el-dialog>-->
    <el-dialog
      title="风版本配置"
      :modal-append-to-body="false"
      :visible.sync="showTreeDialog"
      width="500px"
      class="qmDialog"
    >
      <avue-form
        v-if="showTreeDialog"
        :option="treeDialogOption"
        ref="addForm"
        v-model="treeForm"
      ></avue-form>
      <span slot="footer">
        <el-button
          size="small"
          @click="showTreeDialog = false"
          v-loading="treeDialogLoading"
        >
          {{ $t(`cip.cmn.btn.celBtn`) }}
        </el-button>
        <el-button
          size="small"
          type="primary"
          @click="handleTreeNodeSave"
          v-loading="treeDialogLoading"
        >
          {{ $t(`cip.cmn.btn.defBtn`) }}
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="风险引用"
      top="9vh"
      :modal-append-to-body="false"
      :visible.sync="introduceShow"
      width="80%"
      class="qmDialog"
    >
      <el-container v-if="introduceShow">
        <div>
          <CommonTree
            treeTitle="风险源分类"
            class="introduceClass"
            :defaultProps="defaultProps"
            :isShowdig="false"
            :searchTitle="searchTitle"
            :showCheckbox="false"
            :treeData="introduceTree"
            :treeExpand="false"
            :risk="true"
            node-key="id"
            :defaultCheckedKeys="introduceCheckedKeys"
            :defaultExpandedKeys="introduceExpandedKeys"
            @getNodeClick="handleNodeIntroduce"
          />
          <!--          <CommonTree-->
          <!--            class="introduceClass"-->
          <!--            treeTitle="风险源分类"-->
          <!--            :defaultProps="defaultProps"-->
          <!--            :isShowdig="false"-->
          <!--            :searchTitle="searchTitle"-->
          <!--            :showCheckbox="false"-->
          <!--            :treeData="introduceTree"-->
          <!--            :treeExpand="true"-->
          <!--            :risk="true"-->
          <!--            node-key="id"-->
          <!--            :defaultCheckedKeys="introduceCheckedKeys"-->
          <!--            @getNodeClick="handleNodeIntroduce"-->
          <!--          />-->
        </div>
        <el-main>
          <grid-head-layout
            ref="searchForm"
            :search-columns="introduceSearchColumns"
            @grid-head-search="introduceSearch"
            @grid-head-empty="introduceReset"
          />
          <grid-layout
            class="introduceTable"
            ref="introduceGridLayout"
            :data-total="introduceTableTotal"
            :page="introducePage"
            :tableData="introduceTableData"
            :tableLoading="introduceTableLoading"
            :tableOptions="introduceTableOptions"
            :disabled-list="disabledErpItemList"
            @page-size-change="handleSizeIntroduce"
            @page-current-change="handleCurrentIntroduce"
            @gird-handle-select-click="introduceSelectionChange"
            @grid-select="introduceSelect"
            :search-columns="introduceSearchColumns"
          >
            <template slot="riskCode" slot-scope="{ row }">
              <el-link type="primary" @click="linkDetail(row)"
              >{{ row.riskCode }}
              </el-link>
            </template>

            <template slot="lecdDScore" slot-scope="{ row }">
              <div>{{ row.lecdDScore == -1 ? "" : row.lecdDScore }}</div>
            </template>
            <template slot="riskLevel" slot-scope="{ row }">
              <div
                style="
                  display: flex;
                  justify-content: center;
                  align-item: center;
                  padding: 4px;
                "
              >
                <div
                  :style="{
                    border: `1px solid ${row.lecdColor}`,
                    borderRadius: '4px',
                    color: row.lecdColor,
                    backgroundColor: computedDlevel(row.lecdD).bg,
                    textAlign: 'center',
                    display: 'inline-block',
                    padding: '0 4px',
                  }"
                >
                  {{ row.lecdD }} 级
                </div>
              </div>
            </template>
          </grid-layout>
        </el-main>
      </el-container>
      <span slot="footer">
        <el-button
          size="small"
          @click="introduceShow = false"
          v-loading="treeDialogLoading"
        >
          {{ $t(`cip.cmn.btn.celBtn`) }}
        </el-button>
        <el-button size="small" type="primary" @click="handleIntroduceSave">
          {{ $t(`cip.cmn.btn.defBtn`) }}
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="检查项导入"
      append-to-body
      :visible.sync="excelBox"
      width="555px"
    >
      <div class="import" v-if="excelBox">
        <avue-form
          :option="excelOption"
          v-model="excelOption.excelForm"
          :upload-after="uploadAfter"
        >
          <template slot="excelTemplate">
            <el-button type="primary" @click="handleTemplate">
              {{
                $t("cip.plat.sys.user.field.download")
              }}<i class="el-icon-download el-icon--right"></i>
            </el-button>
          </template>
        </avue-form>
      </div>
    </el-dialog>
    <process-user-dialog
      ref="processUser"
      @getUserSelection="handleUserSelection"
    ></process-user-dialog>
  </div>
</template>

<script>
import {
  getTree,
  addTree,
  deleteTree,
  getPage,
  getTreeDetail,
  getTreesDetail,
  addTreeList,
  deleteTrees,
  deletePage,
  getRiskSourceTypeTree,
  improtList,
  getDetTree,
  getPmriskmap,
  riskVersionList,
  riskVersionAdd,
  riskVersionDelete,
  riskListDeptAdd,
  getCurrentPlate,
  addPage,
  updateTaskId,
  getSourcetree,
  getPageRiskManagementAndControl,
} from "@/api/riskManage/project";
import {getbsrisklecddlevelMap} from "@/api/setting/riskAssessment/index";
import ProcessUserDialog from "@/views/business/components/processUserDialog.vue";
import exForm from "@/views/plugin/workflow/mixins/ex-form";
import {
  processList as getProcessList,
  startProcess,
} from "@/api/plugin/workflow/process";
import {
  getTree as riskTreeList,
  getList as riskTableList,
} from "@/api/safetyTtandard/riskIdentification";

import {mapGetters} from "vuex";
import ServerNameEnum from "@/util/ServerNameEnum";
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
import CommonTree from "@/views/components/com_tree/index";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import {exportBlob} from "@/api/common";
import {downloadXls} from "@/util/util";
import {dateNow} from "@/util/date";
import {getDeptTree} from "@/api/system/dept";
import NProgress from "nprogress";

export default {
  components: {
    CommonTree,
    GridLayout,
    HeadLayout,
    ProcessUserDialog,
  },
  computed: {
    ...mapGetters(["userInfo"]),
    treeDialogOption() {
      return {
        size: "small",
        menuFixed: "right",
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 120,
        column: [
          // {
          //   label: "风险源类型",
          //   prop: "risktype",
          //   type: "radio",
          //   span: 24,
          //   props: {
          //     label: "label",
          //     value: "value",
          //   },
          //   dicData: [
          //     { label: "风险源分类", value: 1 },
          //     { label: "风险源", value: 2 },
          //     { label: "风险版本", value: 3 },
          //   ],
          //   rules: [
          //     {
          //       required: true,
          //       message: "请选择风险源分类",
          //       trigger: "blur",
          //     },
          //   ],
          //   change: (column, id) => {
          //     if (column.value == 3) {
          //       this.parentIdText = false;
          //     } else {
          //       //获取新增时候的树结构
          //       getSourcetree({
          //         organizationId: this.treeNode.id,
          //       }).then((res) => {
          //         if (res.status == 200) {
          //           this.parentIdData = res.data.data;
          //           console.log("res.data.data", res.data.data);
          //         }
          //         this.parentIdText = true;
          //       });
          //     }
          //   },
          // },
          // {
          //   label: "上级分类",
          //   prop: "parentId",
          //   type: "tree",
          //   span: 24,
          //   dicData: this.parentIdData,
          //   value: -1,
          //   display: this.parentIdText,
          //   props: {
          //     label: "sourceTypeName",
          //     value: "id",
          //   },
          //   change: (column, id) => {
          //     this.getNodeId(column, id);
          //   },
          // },
          {
            label: "风险版本名称",
            prop: "sourceTypeName",
            type: "input",
            maxlength: 64,
            overHidden: true,
            span: 24,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: "请输入名称",
                trigger: "blur",
              },
            ],
          },
        ],
      };
    },
    headBtnOptions() {
      let result = [];
      //必须是风险源才能进行新增并且是草稿状态下的
      if (this.quarterItme.type == 3 && this.quarterItme.status == "1") {
        result.push({
          label: "新增",
          emit: "head-add",
          type: "button",
          icon: "",
        });
      }
      if (this.quarterItme.type == 1 && this.quarterItme.status == "1") {
        result.push({
          label: "发布",
          emit: "head-release",
          type: "button",
          icon: "",
        });
      }
      // if (this.quarterItme.status == '1') {
      //   result.push({
      //     label: "导入",
      //     emit: "head-import",
      //     type: "button",
      //     icon: "",
      //   })
      // }
      if (this.quarterItme.type == 1 && this.quarterItme.status == "1") {
        result.push({
          label: "标准库引入",
          emit: "head-introduce",
          type: "button",
          icon: "",
        });
      }
      result.push({
        label: "导出",
        emit: "head-export",
        type: "button",
        icon: "",
      });
      if (this.quarterItme.status == "1") {
        result.push({
          label: "删除",
          emit: "head-delete",
          type: "button",
          icon: "",
        });
      }
      return result;
    },
  },
  mixins: [exForm],
  data() {
    return {
      treeNode: {},
      Props: {
        children: "children",
        label: "title",
      },
      prjSearchTitle: "title",
      excelBox: false,
      riskVersionId: "",
      quarterActive: 0,
      quarterShow: false,
      introduceType: "",
      parentIdText: true,
      parentIdData: [],
      quarterForm: {
        riskVersionName: "",
      },
      pageType: "",
      riskSource: true,
      quarterItme: {},
      show: false,
      //禁用
      disabledErpItemList: [],
      processData: {},
      releaseRow: {},
      quarterFormRules: {
        riskVersionName: [
          {required: true, message: "请输入名称", trigger: "blur"},
        ],
      },
      quarterList: [],
      defaultCheckedKeys: [],
      introduceCheckedKeys: [],
      excelOption: {
        submitBtn: false,
        emptyBtn: false,
        excelForm: {},
        column: [
          {
            label: "数据导入",
            prop: "excelFile",
            type: "upload",
            drag: true,
            loadText: this.$t("cip.plat.sys.user.field.waiting"),
            span: 24,
            propsHttp: {
              res: "data",
            },
            tip: this.$t("cip.plat.sys.user.field.format"),
            action: `${ServerNameEnum.SERVER_FX}/pmrisklibrary/import`,
          },
          {
            label: this.$t("cip.plat.sys.user.field.excelTemplate"),
            prop: "excelTemplate",
            formslot: true,
            span: 24,
          },
        ],
      },
      companySelection: [],
      introduceTableData: [],
      introduceQuery: {},
      introduceArr: [],
      introduceTree: [],
      introduceShow: false,
      introduceTableTotal: 0,
      introduceTableLoading: false,
      introduceTableOptions: {
        index: true,
        indexLabel: "序号",
        menuWidth: 100,
        menu: false,
        selectable: this.selectable,
        column: [
          {
            label: "危险源描述",
            prop: "riskName",
            width: 300,
            align: "left",
            overHidden: true,
          },
          {
            label: "事故类型",
            prop: "event",
            align: "center",
            type: "select",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dicUrl:
              "/api/sinoma-system/dict-biz/dictionary?code=risk_envent_type",
            overHidden: true,
          },
          {
            label: "现有控制措施",
            children: [
              {
                label: "工程技术",
                width: 300,
                align: "left",
                overHidden: true,
                prop: "treatEngineer",
              },
              {
                label: "管理措施",
                width: 300,
                align: "left",
                overHidden: true,
                prop: "treatManagment",
              },
              {
                label: "培训教育",
                width: 300,
                align: "left",
                overHidden: true,
                prop: "treatTraining",
              },
              {
                label: "个体防护",
                width: 300,
                align: "left",
                overHidden: true,
                prop: "treatProtection",
              },
              {
                label: "应急处置",
                width: 300,
                align: "left",
                overHidden: true,
                prop: "treatEmergency",
              },
            ],
          },
          {
            label: "风险评价LECD",
            children: [
              {label: "发生的可能性", width: 150, prop: "lecdL"},
              {label: "发生的频繁程度", width: 150, prop: "lecdE"},
              {label: "后果及严重性", width: 150, prop: "lecdC"},
            ],
          },
          {
            label: "风险等级",
            width: 150,
            prop: "riskLevel",
            align: "left",
            overHidden: true,
          },
          {
            label: "管控层级",
            prop: "controlLevel",
            align: "center",
            type: "select",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dicUrl:
              "/api/sinoma-system/dict-biz/dictionary?code=RISK_CONTROL_LEVEL",
          },
        ],
      },
      introduceSearchColumns: [
        {
          prop: "riskName",
          span: 4,
          placeholder: "请输入危险源描述",
        },
        {
          placeholder: this.$t("cip.cmn.rule.inputWarning") + "事故类型",
          prop: "event",
          span: 4,
          type: "select",
          dicUrl:
            "/api/sinoma-system/dict-biz/dictionary?code=risk_envent_type",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
        },
        {
          placeholder: this.$t("cip.cmn.rule.selectWarning") + "风险等级",
          type: "select",
          prop: "riskLevel",
          span: 4,
          dicData: [],
          props: {
            label: "levelName",
            value: "levelName",
          },
          dataType: "number",
        },
      ],
      introducePage: {
        pageSize: PAGE_CONSTANT.TEN,
        currentPage: 1,
        total: 0,
      },
      page: {
        pageSize: PAGE_CONSTANT.TEN,
        currentPage: 1,
        total: 0,
      },
      treeForm: {risktype: 3},
      searchColumns: [
        {
          placeholder: this.$t("cip.cmn.rule.inputWarning") + "风险编号",
          prop: "riskCode",
          span: 4,
        },
        {
          placeholder: this.$t("cip.cmn.rule.inputWarning") + "风险描述",
          prop: "riskName",
          span: 4,
        },
        {
          placeholder: this.$t("cip.cmn.rule.inputWarning") + "风险源",
          prop: "sourceName",
          span: 4,
        },
        {
          placeholder: this.$t("cip.cmn.rule.selectWarning") + "风险等级",
          type: "select",
          prop: "riskLevel",
          span: 4,
          dicData: [],
          props: {
            label: "levelName",
            value: "levelName",
          },
          dataType: "number",
        },
        // {
        //   placeholder: this.$t("cip.cmn.rule.selectWarning") + "状态",
        //   type: "select",
        //   prop: "status",
        //   span: 4,
        //   dicUrl:
        //     "/api/sinoma-system/dict-biz/dictionary?code=is_training_plan_status",
        //   props: {
        //     label: "dictValue",
        //     value: "dictKey",
        //   },
        //   dicData: [],
        // },
      ],

      searchTitle: "sourceTypeName",
      tableOptions: {
        menuFixed: "right",
        customRowAddBtn: false,
        menuWidth: 200,
        showTooltip: true,
        indexLabel: "序号",
        index: true,
        selectable: (row) => {
          return row.status == "1";
        },
        column: [
          {
            label: "风险编号",
            prop: "riskCode",
            align: "center",
            overHidden: true,
            minWidth: 100,
            slot: true,
          },
          {
            label: "风险描述",
            prop: "riskName",
            align: "center",
            overHidden: true,
            minWidth: 100,
          },
          {
            label: "风险源",
            prop: "sourceName",
            align: "center",
            overHidden: true,
            minWidth: 100,
          },
          {
            label: "事故类型",
            prop: "eventName",
            align: "center",
            overHidden: true,
            minWidth: 100,
          },
          {
            label: "现有控制措施",
            children: [
              {
                label: "工程技术",
                width: 300,
                align: "left",
                overHidden: true,
                prop: "treatEngineer",
              },
              {
                label: "管理措施",
                width: 300,
                align: "left",
                overHidden: true,
                prop: "treatManagment",
              },
              {
                label: "培训教育",
                width: 300,
                align: "left",
                overHidden: true,
                prop: "treatTraining",
              },
              {
                label: "个体防护",
                width: 300,
                align: "left",
                overHidden: true,
                prop: "treatProtection",
              },
              {
                label: "应急处置",
                width: 300,
                align: "left",
                overHidden: true,
                prop: "treatEmergency",
              },
            ],
          },
          {
            label: "风险评价",
            children: [
              {
                label: "L",
                prop: "lecdLScore",
                align: "center",
                overHidden: true,
              },
              {
                label: "E",
                prop: "lecdEScore",
                align: "center",
                overHidden: true,
              },
              {
                label: "C",
                prop: "lecdCScore",
                align: "center",
                overHidden: true,
              },
              {
                slot: true,
                label: "D",
                prop: "lecdDScore",
                align: "center",
                overHidden: true,
              },
            ],
          },
          {
            label: "风险等级",
            prop: "riskLevel",
            align: "center",
            overHidden: true,
            minWidth: 100,
            slot: true,
          },
          // {
          //   label: "状态",
          //   prop: "status",
          //   align: "center",
          //   dicUrl:
          //     "/api/sinoma-system/dict-biz/dictionary?code=is_training_plan_status",
          //   props: {
          //     label: "dictValue",
          //     value: "dictKey",
          //   },
          //   dicData: [],
          //   overHidden: true,
          //   dataType: "number",
          // },
        ],
      },
      showTreeDialog: false,
      tableID: "",
      tableData: [],
      treeData: [],
      defaultProps: {
        children: "children",
        id: "id",
        label: "sourceTypeName",
      },
      companydefaultProps: {
        children: "children",
        id: "id",
        label: "title",
      },
      tableTotal: 0,
      tableLoading: false,
      treeArrData: [],
      isSource: false,
      option: {},
      nowTreeNode: {},
      ids: "",
      D: [],
      query: {},
      mapUrl: "",
      maps: {},
      nowNodeId: "",
      defaultExpandedKeys: [],
      introduceExpandedKeys: [],
    };
  },
  watch: {
    defaultCheckedKeys: {
      // immediate: true,
      handler: function (newVal) {
        if (newVal) {
          this.$nextTick(() => {
            // tree树结构点击会加上下面这个类名
            // 如果默认全部展开-那就会关闭
            document.querySelector(".riskClass .el-tree-node__content").click();
          });
        }
      },
    },
    introduceCheckedKeys: {
      // immediate: true,
      handler: function (newVal) {
        if (newVal) {
          this.$nextTick(() => {
            // tree树结构点击会加上下面这个类名
            // 如果默认全部展开-那就会关闭
            document
              .querySelector(".introduceClass .el-tree-node__content")
              .click();
          });
        }
      },
    },
  },
  mounted() {
    this.pageType = this.$route.query.type;
    this.getbsrisklecddlevelMap();
    this.getProcessData();
    this.excelOption.column[0].action = `${ServerNameEnum.SERVER_FX}/pmrisklibrary/import?organizationId=${this.userInfo.dept_id}`;
  },

  methods: {
    showChange(v) {
      this.show = v;
    },
    selectable(row) {
      return this.disabledErpItemList.indexOf(row.id) === -1;
    },
    handleNode(node) {
      this.quarterItme = {};
      this.treeNode = node;
      // this.getQuarter();
      this.getPmriskmap();
      if (this.pageType == "workflow") {
        this.getdemoWorkflowTree(this.$route.query.id);
      } else {
        this.getdemotree();
      }
    },
    getProcessData() {
      getProcessList(1, 100).then((res) => {
        let data = res.data.data.records;
        data.forEach((item) => {
          if (item.key == "risk_management_project_process") {
            this.processData = item;
          }
        });
      });
    },
    // 获取左侧季度数据
    getQuarter() {
      riskVersionList({createDept: this.treeNode.id}).then((res) => {
        if (res.data.code == 200) {
          this.quarterList = res.data.data;
          if (this.quarterList.length != 0) {
            if (this.pageType == "workflow") {
              let index = this.quarterList.findIndex(
                (element) => element.id === this.$route.query.id
              );
              this.quarterActive = index;
              this.quarterItme = this.quarterList[index];
              this.riskVersionId = this.quarterList[index].id;
            } else {
              this.quarterItme = this.quarterList[0];
              this.riskVersionId = this.quarterList[0].id;
            }
          }
          this.getdemotree();
        }
      });
    },

    //导入
    handleImport() {
      this.excelBox = true;

      this.excelOption.excelForm.excelFile = [];
    },
    uploadAfter(res, done, loading, column) {
      this.excelBox = false;
      this.getdemotree();
      this.getPage();
      this.excelOption.excelForm.excelFile = [];
      done();
    },
    handleTemplate() {
      exportBlob(
        `${ServerNameEnum.SERVER_FX}/pmrisklibrary/exportTemplate`
      ).then((res) => {
        downloadXls(res.data, "风险库数据模板" + ".xls");
      });
    },
    // 发布
    handleRelease() {
      let data = {
        processId: this.processData.id,
        processBussinessName: this.processData.name,
        id: this.riskVersionId,
        deptId: this.userInfo.dept_id,
        assignee: this.assignee,
        typeName: '风险管理'
      };

      this.handleStartProcess7(data).then((res) => {
        this.quarterItme.status = "2";
        this.quarterItme.taskId = res.data.data;
        updateTaskId(this.quarterItme).then((respon) => {
          this.$message({
            message: "提交成功",
            type: "success",
          });
          riskVersionList({createDept: this.treeNode.id}).then((responRi) => {
            this.quarterList = responRi.data.data;
          });
        });
      });
    },
    // 导出
    handleExport() {
      if (!this.nowNodeId) {
        this.$message({
          message: "请选择风险源分类或者风险源",
          type: "warning",
        });
        return;
      }
      console.log("this.tableDatathis.tableData", this.tableData);
      if (!this.tableData.length > 0) {
        this.$message({
          message: "数据为空",
          type: "warning",
        });
        return;
      }
      this.$confirm("是否导出风险管控数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        NProgress.start();
        var cc = "";
        if (this.isSource) {
          cc = "&sourceId=" + this.nowNodeId;
        } else {
          cc = "&sourceTypeId=" + this.nowNodeId;
        }
        let searchForm = {};
        searchForm = this.$refs.searchForm.searchForm;
        let isObject = JSON.stringify(searchForm) == "{}";
        if (isObject == true) {
          searchForm.riskCode = "";
          searchForm.sourceName = "";
          searchForm.riskLevel = "";
          searchForm.riskName = "";
        } else if (
          searchForm.hasOwnProperty("riskCode") == false ||
          searchForm.riskCode == null ||
          searchForm.riskCode == "undefined"
        ) {
          searchForm.riskCode = "";
        } else if (
          searchForm.hasOwnProperty("sourceName") == false ||
          searchForm.sourceName == null ||
          searchForm.sourceName == "undefined"
        ) {
          searchForm.sourceName = "";
        } else if (
          searchForm.hasOwnProperty("riskLevel") == false ||
          searchForm.riskLevel == null ||
          searchForm.riskLevel == "undefined"
        ) {
          searchForm.riskLevel = "";
        } else if (
          searchForm.hasOwnProperty("riskName") == false ||
          searchForm.riskName == null ||
          searchForm.riskName == "undefined"
        ) {
          searchForm.riskName = "";
        }
        exportBlob(
          `${ServerNameEnum.SERVER_FX}/managementRiskPool/export?organizationId=` +
          this.treeNode.id +
          cc +
          `&riskCode=` +
          searchForm.riskCode +
          `&riskName=` +
          searchForm.riskName +
          `&sourceName=` +
          searchForm.sourceName +
          `&riskLevel=` +
          searchForm.riskLevel +
          `&riskVersionId=` +
          this.riskVersionId
        ).then((res) => {
          downloadXls(res.data, "风险管控" + `${dateNow()}.xlsx`);
          NProgress.done();
        });
        // let params = {
        //   organizationId: this.userInfo.dept_id,
        //   ...this.query,
        // };/export
        // if (this.isSource) {
        //   params.sourceId = this.nowNodeId;
        // } else {
        //   params.sourceTypeId = this.nowNodeId;
        // }
        // let paramsStr = "";
        // for (let key in params) {
        //   paramsStr += `${key}=${params[key]}&`;
        // }
        // if (paramsStr.length) {
        //   paramsStr = paramsStr.slice(0, -1);
        // }
        // exportBlob(
        //   `${ServerNameEnum.SERVER_FX}/pmrisklibrary/export?${paramsStr}`
        // ).then((res) => {
        //   downloadXls(
        //     res.data,
        //     `${this.nowTreeNode.sourceTypeName}-风险库数据${dateNow()}.xls`
        //   );
        // });
      });
    },
    linkTool(type) {
      if (type == "export") {
        const src = this.mapUrl;
        const fileName = this.maps.mapImgName;
        const canvas = document.createElement("canvas");
        const img = document.createElement("img");
        // 解决跨域 Canvas 污染问题
        img.setAttribute("crossOrigin", "Anonymous");
        //将资源链接赋值过去，才能触发img.onload事件
        img.src = src;
        img.onload = function (e) {
          canvas.width = img.width;
          canvas.height = img.height;
          const context = canvas.getContext("2d");
          //绘制图片
          context.drawImage(img, 0, 0, img.width, img.height);
          canvas.getContext("2d").drawImage(img, 0, 0, img.width, img.height);
          //将canvas转base64码，然后创建一个a连接自动下载图片
          canvas.toBlob((blob) => {
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
          });
        };
        return;
      }
      let url = `${process.env.VUE_APP_BASE_HMIURL}?organizationId=${this.userInfo.dept_id}`;
      if (type == "add") {
        url = `${process.env.VUE_APP_BASE_HMIURL}?organizationId=${this.userInfo.dept_id}`;
      } else {
        url = `${process.env.VUE_APP_BASE_HMIURL}?id=${this.maps.id}&organizationId=${this.userInfo.dept_id}`;
      }

      window.open(url);
    },
    getPmriskmap() {
      getPmriskmap({organizationId: this.userInfo.dept_id}).then((res) => {
        this.mapUrl = res.data.data.mapUrl || "";
        this.maps = res.data.data;
      });
    },
    linkDetail(row) {
      // if (row.organizationId) {
      //   this.$router.push({
      //     path: "/riskProject/riskProjectDetail",
      //     query: {
      //       type: "detail",
      //       nowRuleFormId: row.id,
      //     },
      //   });
      // }
      this.$router.push({
        path: "/riskProject/riskProjectDetail",
        query: {
          type: "detail",
          nowRuleFormId: row.id,
          riskVersionId: this.riskVersionId,
          organizationId: this.treeNode.id,
        },
      });
    },
    // 获取D映射
    getbsrisklecddlevelMap() {
      //  createDept: this.userInfo.dept_id
      getbsrisklecddlevelMap({}).then((res) => {
        this.D = res.data.data;
        this.searchColumns[3].dicData = res.data.data;
        this.introduceSearchColumns[2].dicData = res.data.data;
      });
    },
    computedDlevel(levelCode) {
      let result = this.D.find((item) => item.levelCode == levelCode);
      if (result) {
        result.bg =
          result.color.substring(0, 3) +
          result.color.substring(3, result.color.length - 2) +
          "0.1)";
      }

      return (
        result || {
          color: "rgba(252, 233, 119, 1)",
          bg: "rgba(252, 233, 119, 0.1)",
        }
      );
    },
    getNodeId(clo, id) {
      if (id) {
        const result = this.treeArrData.find((item) => item.id == id);
        this.isSource = result.isSource;
        this.nowTreeNode = result || {};
      } else if (clo && clo.value) {
        this.treeForm.parentId = clo.value;
        const result = this.treeArrData.find((item) => item.id == clo.value);
        if (result != undefined) {
          this.isSource = result.isSource || false;
        }
        this.nowTreeNode = result || {};
      }
    },
    //编辑
    rowEdit(e, index) {
      this.$router.push({
        path: "/riskProject/riskProjectEdit",
        query: {
          type: "edit",
          nowRuleFormId: e.id,
          riskVersionId: this.riskVersionId,
          organizationId: this.treeNode.id,
        },
      });
    },
    // 发布
    rowRelease(row) {
      this.releaseRow = row;
      let data = {
        processId: this.processData.id,
        processBussinessName: this.processData.name,
        id: this.releaseRow.id,
        deptId: this.userInfo.dept_id,
        assignee: this.assignee,
      };

      this.handleStartProcess7(data).then((res) => {
        this.releaseRow.status = "2";
        this.releaseRow.taskId = res.data.data;
        updateTaskId(this.releaseRow).then((respon) => {
          this.$message({
            message: "提交成功",
            type: "success",
          });
          this.getPage();
        });
      });
    },
    handleUserSelection(userIds) {
      this.assignee = userIds;
      // this.rowRelease(this.releaseRow);
      this.handleRelease();
    },
    rowDel(row) {
      this.$confirm("确认删除当前选中数据吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deletePage({ids: row.id}).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.getPage();
            }
          });
        })
        .catch((error) => {
          this.$message({
            message: "取消删除",
            type: "success",
          });
        });
    },
    //流程树
    getdemoWorkflowTree(row) {
      getSourcetree({
        organizationId: this.treeNode.id,
        id: row,
      }).then((res) => {
        if (res.status == 200) {
          this.treeData = res.data.data;
          this.defaultExpandedKeys = this.treeData.map((item) => {
            return item.id;
          });
          this.treeArrData = this.flattenTree(res.data.data);
          if (this.treeData.length != 0) {
            this.defaultCheckedKeys.push(this.treeData[0].id);
          } else {
            this.nowNodeId = undefined;
            this.isSource = false;
            this.tableData = [];
            this.tableTotal = 0;
          }
        }
      });
    },
    //树
    getdemotree() {
      getSourcetree({
        organizationId: this.treeNode.id,
      }).then((res) => {
        if (res.status == 200) {
          this.treeData = res.data.data;
          this.defaultExpandedKeys = this.treeData.map((item) => {
            return item.id;
          });
          this.treeArrData = this.flattenTree(res.data.data);
          if (this.treeData.length != 0) {
            this.defaultCheckedKeys.push(this.treeData[0].id);
          } else {
            this.nowNodeId = undefined;
            this.isSource = false;
            this.tableData = [];
            this.tableTotal = 0;
          }
        }
      });
    },
    getTreeDialogOption(id) {
      //获取新增时候的树结构
      getSourcetree({
        organizationId: this.treeNode.id,
      }).then((res) => {
        if (res.status == 200) {
          this.treeDialogOption.column[1].dicData = res.data.data;
        }
      });
    },

    //扁平化 树
    flattenTree(tree, parent = null) {
      let result = [];
      for (let node of tree) {
        let flatNode = {...node, parent};
        result.push(flatNode);
        if (node.children && node.children.length) {
          result = result.concat(this.flattenTree(node.children, flatNode));
        }
        delete flatNode.children;
      }
      return result;
    },
    opDialog() {
      this.$router.push({
        path: "/riskProject/riskProjectAdd",
        query: {
          type: "add",
          nowRuleFormId: "",
          organizationId: this.treeNode.id,
          riskSource: this.isSource ? this.nowNodeId : "",
          riskVersionId: this.riskVersionId,
          sourceId: this.quarterItme.id,
          sourceName: this.quarterItme.sourceTypeName,
        },
      });
    },
    deletesList() {
      if (this.ids) {
        this.$confirm("确认删除当前选中数据吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            deletePage({ids: this.ids}).then((res) => {
              if (res.data.code == 200) {
                this.$message({
                  message: "删除成功",
                  type: "success",
                });
                this.getPage();
              }
            });
          })
          .catch((error) => {
            this.$message({
              message: "取消删除",
              type: "success",
            });
          });
      } else {
        this.$message({
          message: "请选择需要删除的数据",
          type: "warning",
        });
      }
    },

    //点击树节点显示列表
    handleNodeClick(data) {
      if (data.type == "1") {
        this.riskSource = true
      } else {
        this.riskSource = false
      }
      this.quarterItme = data;
      this.riskVersionId = this.quarterItme.riskVersionId;
      this.nowNodeId = data.id;
      this.getNodeId({}, this.nowNodeId);
      let params = {
        size: this.page.pageSize,
        current: this.page.currentPage,
        organizationId: this.treeNode.id,
      };
      if (this.isSource) {
        params.sourceId = this.nowNodeId;
      } else {
        params.sourceTypeId = this.nowNodeId;
      }
      // 调用列表接口;
      this.tableLoading = true;
      getPageRiskManagementAndControl(params)
        .then((res) => {
          this.tableData = res.data.data.records;
          this.tableTotal = res.data.data.total;
          this.tableLoading = false;
        })
        .catch((error) => {
          this.tableLoading = false;
        });
    },
    getPage() {
      this.tableLoading = true;
      let params = {
        size: this.page.pageSize,
        current: this.page.currentPage,
        organizationId: this.treeNode.id,
      };
      if (this.isSource) {
        params.sourceId = this.nowNodeId;
      } else {
        params.sourceTypeId = this.nowNodeId;
      }

      // 调用列表接口;
      getPageRiskManagementAndControl(params)
        .then((res) => {
          this.tableData = res.data.data.records;
          this.tableTotal = res.data.data.total;
          this.tableLoading = false;
        })
        .catch((error) => {
          this.tableLoading = false;
        });
    },
    clearSelected() {
      let allNode = document.querySelectorAll("#tree-option .el-tree-node");
      allNode.forEach((element) => element.classList.remove("is-current"));
    },
    //size改变
    handleSizeChange(e) {
      this.page.pageSize = e.pageSize;
      this.getPage();
    },
    //页数改变
    handleCurrentChange(e) {
      this.page.currentPage = e.currentPage;
      this.getPage();
    },
    handleSizeIntroduce(e) {
      this.introducePage.pageSize = e.pageSize;
      this.getRiskTableList(this.introducePage);
    },

    handleCurrentIntroduce(e) {
      this.introducePage.currentPage = e.currentPage;
      this.getRiskTableList(this.introducePage);
    },
    // 新增风险源
    async getTreeAdd() {
      if (this.isSource) {
        this.$message({
          message: "当前选中的是风险源节点不可新增",
          type: "warning",
        });
        return;
      }
      if (this.nowNodeId == undefined) {
        this.treeForm = {
          parentId: this.treeDialogOption.column[1].value,
        };
      } else {
        this.treeForm = {
          parentId: this.nowNodeId,
        };
      }
      this.showTreeDialog = true;

      //获取新增时候的树结构
      // getSourcetree({
      //   organizationId: this.treeNode.id,
      // }).then((res) => {
      //   if (res.status == 200) {
      //     this.treeDialogOption.column[1].dicData = res.data.data;
      //   }
      //   if (this.nowNodeId) {
      //     this.treeForm.parentId = this.nowNodeId;
      //     this.treeDialogOption.column[1].value = this.nowNodeId;
      //   }
      //   this.showTreeDialog = true;
      // });
    },
    // 删除
    getTreeDelete() {
      if (this.nowNodeId) {
        this.$confirm("确认删除当前选中节点吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            if (!this.isSource) {
              if (this.tableData.length > 0) {
                return this.$message({
                  message: "风险源下有数据无法删除，请先删除数据",
                  type: "warning",
                });
              }
              deleteTree({ids: this.nowNodeId}).then((res) => {
                this.$message({
                  message: "删除成功",
                  type: "success",
                });
                if (
                  this.nowTreeNode.children &&
                  this.nowTreeNode.children.length
                ) {
                  deleteTrees({ids: this.nowNodeId}).then((res) => {
                  });
                }
                this.getdemotree();
              });
            } else {
              console.log("321");
              deleteTrees({ids: this.nowNodeId}).then((res) => {
                this.$message({
                  message: "删除成功",
                  type: "success",
                });
                this.getdemotree();
              });
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      } else {
        this.$message({
          message: "请选择一个节点",
          type: "warning",
        });
      }
    },
    getTreeEdit() {
      this.treeForm = {};
      this.getNodeId({}, this.nowNodeId);
      if (!this.nowNodeId) {
        this.$message({
          message: "请选中一个节点进行编辑",
          type: "warning",
        });
      }
      //this.treeForm.risktype == 1
      // if (this.isSource) {
      //   this.getTreeDialogOption();
      //   getTreesDetail({id: this.nowNodeId}).then((res) => {
      //     this.treeForm = res.data.data;
      //     this.treeForm.risktype = 2;
      //     this.treeForm.sourceTypeName = this.treeForm.sourceName;
      //     this.treeForm.parentId = this.treeForm.sourceTypeId;
      //     this.treeForm.sourceTypeCode = this.treeForm.sourceCode;
      //     this.showTreeDialog = true;
      //   });
      // } else {
      this.getTreeDialogOption(this.nowNodeId);
      getTreeDetail({id: this.nowNodeId}).then((res) => {
        this.treeForm = res.data.data;
        this.showTreeDialog = true;
      });
      // }
    },
    selectionChange(arr) {
      this.ids = arr
        .map((item) => {
          return item.id;
        })
        .join(",");
    },

    getIntroducetree(type) {
      this.introduceType = type;
      riskTreeList({
        sectionId: type,
      }).then((res) => {
        if (res.status == 200) {
          this.introduceTree = res.data.data;
          this.introduceExpandedKeys = this.introduceTree.map((item) => {
            return item.id;
          });
          this.introduceCheckedKeys.push(this.introduceTree[0].id);
        }
      });
    },
    // 点击树显示列表 getPageManage
    handleNodeIntroduce(data) {
      this.IntroduceNowNodeId = data.id;
      this.introduceNowTreeNode = data;
      this.introducePage.currentPage = 1;
      this.getRiskTableList(this.introducePage);
      // let params = {
      //   size: this.introducePage.pageSize,
      //   current: this.introducePage.currentPage,
      //   organizationId: this.organizationId,
      // };
      // if (this.introduceNowTreeNode.isSource) {
      //   params.sourceId = this.IntroduceNowNodeId;
      // } else {
      //   params.sourceTypeId = this.IntroduceNowNodeId;
      // }
      // // 调用列表接口;
      // this.introduceTableLoading = true;
      // getPage(params)
      //   .then((res) => {
      //     this.introduceTableData = res.data.data.records;
      //     this.introduceTableTotal = res.data.data.total;
      //     this.introduceTableLoading = false;
      //   })
      //   .catch((error) => {
      //     this.introduceTableLoading = false;
      //   });
    },
    getRiskTableList(page) {
      this.introducePage = page;
      let params = {
        sourceId: this.IntroduceNowNodeId,
        sectionId: this.introduceType,
        type: "popUpLayer",
        riskVersionId: this.riskVersionId,
        ...this.introduceQuery,
      };

      riskTableList(
        this.introducePage.currentPage,
        this.introducePage.pageSize,
        params
      )
        .then((res) => {
          this.introduceTableData = res.data.data.records;
          this.disabledErpItemList = this.introduceTableData.map((e) => {
            if (e.forbidden == "1") {
              return e.id;
            }
          });
          this.introduceTableTotal = res.data.data.total;
          this.$refs.introduceGridLayout.page.total = res.data.data.total;
          this.introduceTableLoading = false;
        })
        .catch((error) => {
          this.introduceTableLoading = false;
        });
    },
    introduceSelectionChange(arr) {
      this.introduceArr = arr;
      // .map((item) => {
      //   return item.id;
      // })
      // .join(",");
    },

    introduceSearch(query) {
      this.introduceQuery = query;

      this.introducePage.currentPage = 1;
      this.getRiskTableList(this.introducePage);
    },
    introduceReset(query) {
      this.introduceQuery = query;
      this.introducePage.currentPage = 1;
      this.getRiskTableList(this.introducePage);
    },
    searchReset() {
      this.query = {};
      this.getPage();
    },
    searchChange(query) {
      this.query = query;
      let params = {
        ...query,
        size: this.page.pageSize,
        current: this.page.currentPage,
        organizationId: this.treeNode.id,
      };
      if (this.isSource) {
        params.sourceId = this.nowNodeId;
      } else {
        params.sourceTypeId = this.nowNodeId;
      }
      // 调用列表接口;
      getPageRiskManagementAndControl(params)
        .then((res) => {
          this.tableData = res.data.data.records;
          this.tableTotal = res.data.data.total;
          this.tableLoading = false;
        })
        .catch((error) => {
          this.tableLoading = false;
        });
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    // 引入
    headIntroduce() {
      getCurrentPlate().then((res) => {
        if (res.data.code == 200) {
          this.getIntroducetree(res.data.data);
          // this.getRiskTableList(this.introducePage);
          this.introduceShow = true;
        }
      });
    },
    // 保存引用
    handleIntroduceSave() {
      if (!this.introduceArr.length) {
        return this.$message({
          message: "请勾选需要引入的风险源",
          type: "warning",
        });
      }
      let ids = [];
      this.introduceArr.forEach((item) => {
        ids.push(item.id);
      });
      riskListDeptAdd({
        treeId: this.IntroduceNowNodeId,
        ids: ids.join(","),
        riskVersionId: this.nowNodeId,
        organizationId: this.treeNode.id,
      }).then((res) => {
        this.$message({
          message: "操作成功",
          type: "success",
        });
        this.introduceShow = false;
        this.getdemotree();
      });

      // this.introduceArr.forEach((item) => {
      //   item.organizationId = this.userInfo.dept_id;
      //   item.sourceId = this.nowTreeNode.id;
      //   item.sourceCode = this.nowTreeNode.sourceCode;
      //   item.sourceName = this.nowTreeNode.sourceName;
      // });
      // improtList(
      //   this.introduceArr,
      //   this.userInfo.dept_id,
      //   this.nowTreeNode.id
      // ).then((res) => {
      //   this.introduceShow = false;
      //   // 引入成功
      //   this.$message({
      //     message: "引入成功",
      //     type: "success",
      //   });
      //   this.getdemotree();
      //   this.getPage();
      // });
    },
    introduceSelect(row) {
      // row.organizationId = this.userInfo.dept_id;
      // row.sourceId = this.nowTreeNode.id;
      // row.sourceCode = this.nowTreeNode.sourceCode;
      // row.sourceName = this.nowTreeNode.sourceName;
      improtList([row], this.userInfo.dept_id, this.nowTreeNode.id).then(
        (res) => {
          this.introduceShow = false;
          // 引入成功
          this.$message({
            message: "引入成功",
            type: "success",
          });
          this.getdemotree();
          this.getPage();
        }
      );
    },
    handleTreeNodeSave() {
      // 新增树节点
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          if (!this.isSource) {
            if (this.treeForm.risktype == 1) {
              if (!this.treeForm.parentId) {
                this.showTreeDialog = false;
                return this.$message({
                  message: "请选择风险分类或者风险版本",
                  type: "warning",
                });
              }
              if (this.nowTreeNode.type == 3) {
                this.showTreeDialog = false;
                return this.$message({
                  message: "新增风险源上级分类必须是风险分类或者风险版本",
                  type: "warning",
                });
              }
              addTree({
                ...this.treeForm,
                parentId: this.treeForm.parentId
                  ? this.treeForm.parentId
                  : "-1",
                createDept: this.userInfo.dept_id,
                organizationId: this.treeNode.id,
                riskVersionId: this.riskVersionId,
              }).then((res) => {
                if (res.data.code == 200) {
                  this.$message({
                    message: "保存成功",
                    type: "success",
                  });
                  this.showTreeDialog = false;
                  this.getdemotree();
                }
              });
            } else if (this.treeForm.risktype == 2) {
              if (!this.treeForm.parentId) {
                this.showTreeDialog = false;
                return this.$message({
                  message: "没有上级分类不可新增风险源",
                  type: "warning",
                });
              }
              if (this.nowTreeNode.type != 2) {
                this.showTreeDialog = false;
                return this.$message({
                  message: "新增风险源上级分类必须是风险分类",
                  type: "warning",
                });
              } else {
                addTreeList({
                  ...this.treeForm,
                  sourceTypeCode: this.nowTreeNode.sourceTypeCode,
                  sourceTypeName: this.nowTreeNode.sourceTypeName,
                  sourceTypeId: this.nowTreeNode.id,
                  sourceName: this.treeForm.sourceTypeName,
                  createDept: this.userInfo.dept_id,
                  organizationId: this.treeNode.id,
                  riskVersionId: this.riskVersionId,
                }).then((res) => {
                  if (res.data.code == 200) {
                    this.$message({
                      message: "保存成功",
                      type: "success",
                    });
                    this.showTreeDialog = false;
                    this.getdemotree();
                  }
                });
              }
            } else {
              this.quarterForm.createDept = this.treeNode.value;
              this.quarterForm.riskVersionName = this.treeForm.sourceTypeName;
              if (this.treeForm.id) {
                this.quarterForm.id = this.treeForm.id;
              }
              riskVersionAdd(this.quarterForm).then((res) => {
                if (res.data.code == 200) {
                  this.$message({
                    message: "保存成功",
                    type: "success",
                  });
                  this.showTreeDialog = false;
                  this.getQuarter();
                }
              });
            }
          } else {
            this.$message({
              message: "上级分类节点不能为风险源",
              type: "warning",
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.leftQuarter {
  width: 200px;
  height: calc(100% - 12px);
  padding: 6px;
  background-color: #ffffff;
  border-right: 1px solid #cccccc;
}

.riskClass {
  ::v-deep .common_tree_box .common_tree_handle {
    width: 240px;
  }
}

.quarterBox {
  width: calc(100% - 14px);
  padding: 0 6px;
  border: 1px solid #d4d4d4;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  color: #606060;
  background-color: #fafafa;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  margin-bottom: 8px;
  position: relative;

  .deleteIcon {
    font-size: 16px;
    position: absolute;
    top: 3px;
    right: 3px;
    cursor: pointer;
    color: #f56c6c;
  }
}

.quarterActiveClass {
  border: 1px solid #2979ff;
  background-color: #b3d8ff;
}

.quarterAddBox {
  width: calc(100% - 14px);
  padding: 0 6px;
  border: 1px dashed #d4d4d4;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  color: #606060;
  background-color: #fafafa;
  text-align: center;
  cursor: pointer;
}

.introduceClass ::v-deep .common_tree_handle .el-tree {
  height: calc(100vh - 421px) !important;
}

::v-deep .introduceTable .avue-crud .el-table {
  height: calc(100vh - 385px) !important;
  max-height: calc(100vh - 385px) !important;
}

.main-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .top {
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .canvas-box {
      border-radius: 4px;
      width: 50%;
      height: 100%;
      background: #fff;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;

      .title-box {
        width: calc(100% - 20px);
        position: absolute;
        z-index: 10;
        left: 10px;
        top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  .bottom {
    border-radius: 4px;
    margin-top: 10px;
    background: #fff;
    display: flex;
    width: 100%;
    height: calc(50% - 10px);
    overflow: scroll;

    .el-container {
      height: 100%;

      .el-main {
        height: 100%;
        overflow: hidden;
      }
    }
  }
}

.el-main {
  overflow: hidden;
}

.tagBox {
  position: absolute;
  left: 100px;
  top: 9px;
  z-index: 99;
}
</style>
