var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-content" },
    [
      _c(
        "div",
        { staticClass: "content", staticStyle: { position: "relative" } },
        [
          _c(
            "el-container",
            [
              _c("CommonTreeNew", {
                ref: "commonTree",
                attrs: {
                  treeTitle: "组织架构",
                  defaultProps: _vm.Props,
                  showCheckbox: false,
                  urlParmas: {
                    tenantId: "",
                    deptCategory: "5,2",
                    parentId: _vm.userInfo.dept_id,
                  },
                  "node-key": "id",
                  isShowdig: false,
                  isZoom: true,
                  show: _vm.show,
                },
                on: {
                  getNodeClick: _vm.handleNode,
                  showChange: _vm.showChange,
                },
              }),
              _c("CommonTree", {
                staticClass: "riskClass",
                attrs: {
                  treeTitle: "风险源",
                  defaultProps: _vm.defaultProps,
                  isShowdig: _vm.pageType != "workflow" && _vm.riskSource,
                  searchTitle: _vm.searchTitle,
                  showCheckbox: false,
                  treeData: _vm.treeData,
                  treeExpand: false,
                  risk: true,
                  "node-key": "id",
                  defaultCheckedKeys: _vm.defaultCheckedKeys,
                  defaultExpandedKeys: _vm.defaultExpandedKeys,
                },
                on: {
                  getNodeClick: _vm.handleNodeClick,
                  getTreeAdd: _vm.getTreeAdd,
                  getTreeDelete: _vm.getTreeDelete,
                  getTreeEdit: _vm.getTreeEdit,
                },
              }),
              _c(
                "el-main",
                { staticStyle: { position: "relative" } },
                [
                  _c(
                    "div",
                    { staticClass: "tagBox" },
                    [
                      _vm.quarterItme.status == "1"
                        ? _c(
                            "el-tag",
                            { attrs: { size: "medium", type: "info" } },
                            [_vm._v("草稿\n          ")]
                          )
                        : _vm._e(),
                      _vm.quarterItme.status == "3"
                        ? _c(
                            "el-tag",
                            { attrs: { size: "medium", type: "success" } },
                            [_vm._v("已发布\n          ")]
                          )
                        : _vm._e(),
                      _vm.quarterItme.status == "2"
                        ? _c(
                            "el-tag",
                            { attrs: { size: "medium", type: "warning" } },
                            [_vm._v("已提交\n          ")]
                          )
                        : _vm._e(),
                      _vm.quarterItme.status == "4"
                        ? _c(
                            "el-tag",
                            { attrs: { size: "medium", type: "danger" } },
                            [_vm._v("被驳回\n          ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("head-layout", {
                    attrs: {
                      "head-btn-options": _vm.headBtnOptions,
                      "head-title": "风险库",
                    },
                    on: {
                      "head-add": function ($event) {
                        return _vm.opDialog()
                      },
                      "head-delete": _vm.deletesList,
                      "head-export": _vm.handleExport,
                      "head-release": _vm.handleRelease,
                      "head-import": _vm.handleImport,
                      "head-introduce": _vm.headIntroduce,
                    },
                  }),
                  _c("grid-head-layout", {
                    ref: "searchForm",
                    attrs: {
                      searchSpan: "4",
                      "search-columns": _vm.searchColumns,
                    },
                    on: {
                      "grid-head-search": _vm.searchChange,
                      "grid-head-empty": _vm.searchReset,
                    },
                  }),
                  _c("grid-layout", {
                    ref: "gridLayout",
                    attrs: {
                      "data-total": _vm.tableTotal,
                      page: _vm.page,
                      tableData: _vm.tableData,
                      tableLoading: _vm.tableLoading,
                      tableOptions: _vm.tableOptions,
                      "search-columns": _vm.searchColumns,
                    },
                    on: {
                      "page-size-change": _vm.handleSizeChange,
                      "page-current-change": _vm.handleCurrentChange,
                      "page-refresh-change": _vm.onLoad,
                      "gird-handle-select-click": _vm.selectionChange,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "riskLevel",
                          fn: function ({ row }) {
                            return [
                              row.lecdD
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "justify-content": "center",
                                        "align-item": "center",
                                        padding: "4px",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          style: {
                                            border: `1px solid ${row.lecdColor}`,
                                            borderRadius: "4px",
                                            color: row.lecdColor,
                                            backgroundColor: _vm.computedDlevel(
                                              row.lecdD
                                            ).bg,
                                            textAlign: "center",
                                            display: "inline-block",
                                            padding: "0 20px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(row.lecdD) +
                                              " 级\n              "
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                        {
                          key: "lecdDScore",
                          fn: function ({ row }) {
                            return [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    row.lecdDScore == -1 ? "" : row.lecdDScore
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                        {
                          key: "riskCode",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "el-link",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.linkDetail(row)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(row.riskCode) + "\n            "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                        _vm.quarterItme.status == "1"
                          ? {
                              key: "customBtn",
                              fn: function ({ row, index }) {
                                return [
                                  row.status == 1 &&
                                  row.createUser == _vm.userInfo.user_id
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "text",
                                            size: "small",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.rowEdit(row, index)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n              编辑\n            "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.linkDetail(row, index)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              查看\n            "
                                      ),
                                    ]
                                  ),
                                  row.status == 1 &&
                                  row.createUser == _vm.userInfo.user_id
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "text",
                                            size: "small",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.rowDel(row, index)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n              删除\n            "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            }
                          : null,
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "qmDialog",
          attrs: {
            title: "风版本配置",
            "modal-append-to-body": false,
            visible: _vm.showTreeDialog,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showTreeDialog = $event
            },
          },
        },
        [
          _vm.showTreeDialog
            ? _c("avue-form", {
                ref: "addForm",
                attrs: { option: _vm.treeDialogOption },
                model: {
                  value: _vm.treeForm,
                  callback: function ($$v) {
                    _vm.treeForm = $$v
                  },
                  expression: "treeForm",
                },
              })
            : _vm._e(),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.treeDialogLoading,
                      expression: "treeDialogLoading",
                    },
                  ],
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.showTreeDialog = false
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t(`cip.cmn.btn.celBtn`)) +
                      "\n      "
                  ),
                ]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.treeDialogLoading,
                      expression: "treeDialogLoading",
                    },
                  ],
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleTreeNodeSave },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t(`cip.cmn.btn.defBtn`)) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "qmDialog",
          attrs: {
            title: "风险引用",
            top: "9vh",
            "modal-append-to-body": false,
            visible: _vm.introduceShow,
            width: "80%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.introduceShow = $event
            },
          },
        },
        [
          _vm.introduceShow
            ? _c(
                "el-container",
                [
                  _c(
                    "div",
                    [
                      _c("CommonTree", {
                        staticClass: "introduceClass",
                        attrs: {
                          treeTitle: "风险源分类",
                          defaultProps: _vm.defaultProps,
                          isShowdig: false,
                          searchTitle: _vm.searchTitle,
                          showCheckbox: false,
                          treeData: _vm.introduceTree,
                          treeExpand: false,
                          risk: true,
                          "node-key": "id",
                          defaultCheckedKeys: _vm.introduceCheckedKeys,
                          defaultExpandedKeys: _vm.introduceExpandedKeys,
                        },
                        on: { getNodeClick: _vm.handleNodeIntroduce },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-main",
                    [
                      _c("grid-head-layout", {
                        ref: "searchForm",
                        attrs: { "search-columns": _vm.introduceSearchColumns },
                        on: {
                          "grid-head-search": _vm.introduceSearch,
                          "grid-head-empty": _vm.introduceReset,
                        },
                      }),
                      _c("grid-layout", {
                        ref: "introduceGridLayout",
                        staticClass: "introduceTable",
                        attrs: {
                          "data-total": _vm.introduceTableTotal,
                          page: _vm.introducePage,
                          tableData: _vm.introduceTableData,
                          tableLoading: _vm.introduceTableLoading,
                          tableOptions: _vm.introduceTableOptions,
                          "disabled-list": _vm.disabledErpItemList,
                          "search-columns": _vm.introduceSearchColumns,
                        },
                        on: {
                          "page-size-change": _vm.handleSizeIntroduce,
                          "page-current-change": _vm.handleCurrentIntroduce,
                          "gird-handle-select-click":
                            _vm.introduceSelectionChange,
                          "grid-select": _vm.introduceSelect,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "riskCode",
                              fn: function ({ row }) {
                                return [
                                  _c(
                                    "el-link",
                                    {
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.linkDetail(row)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(row.riskCode) + "\n            "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "lecdDScore",
                              fn: function ({ row }) {
                                return [
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        row.lecdDScore == -1
                                          ? ""
                                          : row.lecdDScore
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                            {
                              key: "riskLevel",
                              fn: function ({ row }) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "justify-content": "center",
                                        "align-item": "center",
                                        padding: "4px",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          style: {
                                            border: `1px solid ${row.lecdColor}`,
                                            borderRadius: "4px",
                                            color: row.lecdColor,
                                            backgroundColor: _vm.computedDlevel(
                                              row.lecdD
                                            ).bg,
                                            textAlign: "center",
                                            display: "inline-block",
                                            padding: "0 4px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(row.lecdD) +
                                              " 级\n              "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1870387763
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.treeDialogLoading,
                      expression: "treeDialogLoading",
                    },
                  ],
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.introduceShow = false
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t(`cip.cmn.btn.celBtn`)) +
                      "\n      "
                  ),
                ]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleIntroduceSave },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t(`cip.cmn.btn.defBtn`)) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "检查项导入",
            "append-to-body": "",
            visible: _vm.excelBox,
            width: "555px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.excelBox = $event
            },
          },
        },
        [
          _vm.excelBox
            ? _c(
                "div",
                { staticClass: "import" },
                [
                  _c(
                    "avue-form",
                    {
                      attrs: {
                        option: _vm.excelOption,
                        "upload-after": _vm.uploadAfter,
                      },
                      model: {
                        value: _vm.excelOption.excelForm,
                        callback: function ($$v) {
                          _vm.$set(_vm.excelOption, "excelForm", $$v)
                        },
                        expression: "excelOption.excelForm",
                      },
                    },
                    [
                      _c(
                        "template",
                        { slot: "excelTemplate" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.handleTemplate },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t("cip.plat.sys.user.field.download")
                                  )
                              ),
                              _c("i", {
                                staticClass: "el-icon-download el-icon--right",
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c("process-user-dialog", {
        ref: "processUser",
        on: { getUserSelection: _vm.handleUserSelection },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }